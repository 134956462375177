/* .backgroundStyle{
    background-image:url('./assets/background/FrontViewMockupDeeezy1copy.jpg');
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #000;
    width:100%;
    height:100%;
}
 */
/* @media (max-width:991px){
    .backgroundStyle{
        width:100%;
        height:100%
    }
} */



.info{
    background-color: rgba(0,0,0,0.8);
    display:flex;
    flex-direction:column;
    align-items:center;
    padding-top:3em;
    padding-bottom:3em;
    font-family:'DinPro';
    color:white;
}
