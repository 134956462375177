.headerP{
    color:#fff;
    font-family:'DinCondensedBold';
    font-size:5em;
    margin-bottom:-40px;
    font-style:italic;
    text-shadow: 0 0 20px #ff5200;
}

.neon{
    color:#fff;
    font-family:'DinCondensedBold';
    font-weight: 100;
    text-shadow: 0 0 20px #ff5200;
}

