.linkContainerB{
  background-image: url('/src/assets/background/blonde.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  width:100vw;
  height:100vh;
  padding:20px 0;
  box-sizing:border-box;
  display:grid;
  place-items:center;
}
