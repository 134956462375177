
.container{
    width:100%;
    height:100%;
    padding:20px 0;
    box-sizing:border-box;
    display:grid;
    place-items:center;
}

.card{
    background-color: transparent;
    box-shadow: 0px 7px 10px rgba(0,0,0,0.5);
    transition:0.5s all;
    position: relative;
}

.card:hover{
    transform: translateY(20px);
}
.card:before{
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:block;
    background: linear-gradient(to bottom, rgba(170, 194, 191, 0.267), rgba(149, 153, 143, 0.5));
    z-index: 2;
    transition: 0.5s;
    opacity:0;
}

.card:hover:before{
    opacity:1;
}