@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  font-family:DinCondensedBold, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#000;
  background-image:url(/static/media/FrontViewMockupDeeezy1copy.1db1e03f.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width:100%;
  height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .backgroundStyle{
    background-image:url('./assets/background/FrontViewMockupDeeezy1copy.jpg');
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #000;
    width:100%;
    height:100%;
}
 */
/* @media (max-width:991px){
    .backgroundStyle{
        width:100%;
        height:100%
    }
} */



.info{
    background-color: rgba(0,0,0,0.8);
    display:flex;
    flex-direction:column;
    align-items:center;
    padding-top:3em;
    padding-bottom:3em;
    font-family:'DinPro';
    color:white;
}

i {
  margin-right: 10px;
}
.navbar-logo {
  padding: 15px;
  color: #fff;
}
.navbar-mainbg {
  background-color: #000000;
  padding: 0px;
}
#navbarSupportedContent {
  overflow: hidden;
  position: relative;
}
#navbarSupportedContent ul {
  padding: 0px;
  margin: 0px;
}
#navbarSupportedContent ul li a i {
  margin-right: 10px;
}
#navbarSupportedContent li {
  list-style-type: none;
  float: left;
}
#navbarSupportedContent ul li a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  font-size: 15px;
  display: block;
  padding: 20px 20px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
}
#navbarSupportedContent > ul > li.active > a {
  color: #000000;
  background-color: transparent;
  transition: all 0.7s;
}
#navbarSupportedContent a:not(:only-child):after {
  content: '\f105';
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 14px;
  font-family: 'DinPro';
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  transition: 0.5s;
}
#navbarSupportedContent .active > a:not(:only-child):after {
  transform: rotate(90deg);
}

.hori-selector {
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 0px;
  left: 0px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: 10px;
}
.hori-selector .right,
.hori-selector .left {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #fff;
  bottom: 10px;
}
.hori-selector .right {
  right: -25px;
}
.hori-selector .left {
  left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000000;
}
.hori-selector .right:before {
  bottom: 0;
  right: -25px;
}
.hori-selector .left:before {
  bottom: 0;
  left: -25px;
}
@media (max-width: 991px) {
  #navbarSupportedContent ul li a {
    padding: 12px 30px;
  }
  .hori-selector {
    margin-top: 0px;
    margin-left: 10px;
    border-radius: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .hori-selector .left,
  .hori-selector .right {
    right: 10px;
  }
  .hori-selector .left {
    top: -25px;
    left: auto;
  }
  .hori-selector .right {
    bottom: -25px;
  }
  .hori-selector .left:before {
    left: -25px;
    top: -25px;
  }
  .hori-selector .right:before {
    bottom: -25px;
    left: -25px;
  }
}
.headerP{
    color:#fff;
    font-family:'DinCondensedBold';
    font-size:5em;
    margin-bottom:-40px;
    font-style:italic;
    text-shadow: 0 0 20px #ff5200;
}

.neon{
    color:#fff;
    font-family:'DinCondensedBold';
    font-weight: 100;
    text-shadow: 0 0 20px #ff5200;
}



.container{
    width:100%;
    height:100%;
    padding:20px 0;
    box-sizing:border-box;
    display:grid;
    place-items:center;
}

.card{
    background-color: transparent;
    box-shadow: 0px 7px 10px rgba(0,0,0,0.5);
    transition:0.5s all;
    position: relative;
}

.card:hover{
    transform: translateY(20px);
}
.card:before{
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:block;
    background: linear-gradient(to bottom, rgba(170, 194, 191, 0.267), rgba(149, 153, 143, 0.5));
    z-index: 2;
    transition: 0.5s;
    opacity:0;
}

.card:hover:before{
    opacity:1;
}
.linkContainerB{
  background-image: url(/static/media/blonde.5389c440.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  width:100vw;
  height:100vh;
  padding:20px 0;
  box-sizing:border-box;
  display:grid;
  place-items:center;
}

.linkContainerH{
    background-image: url(/static/media/honey.09f1ebbc.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #000;
    width:100vw;
    height:100vh;
    padding:20px 0;
    box-sizing:border-box;
    display:grid;
    place-items:center;
  }
  
.linkContainerSc{
    background-image: url(/static/media/scottish.faf445b2.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #000;
    width:100vw;
    height:100vh;
    padding:20px 0;
    box-sizing:border-box;
    display:grid;
    place-items:center;
  }
  
.linkContainerI{
    background-image: url(/static/media/ipa.db1f7559.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #000;
    width:100vw;
    height:100vh;
    padding:20px 0;
    box-sizing:border-box;
    display:grid;
    place-items:center;
  }
  
.linkContainerA{
    background-image: url(/static/media/AAAcopy.fd89e832.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #000;
    width:100vw;
    height:100vh;
    padding:20px 0;
    box-sizing:border-box;
    display:grid;
    place-items:center;
  }
  
.linkContainerSt{
    background-image: url(/static/media/Drystout.d3485df7.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #000;
    width:100vw;
    height:100vh;
    padding:20px 0;
    box-sizing:border-box;
    display:grid;
    place-items:center;
  }
  
